<template>
  <el-card class="box-card">
    <div
        slot="header"
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 50px 0 10px;
        "
        class="clearfix"
    >
      <h3 class="inBlock">福袋中奖名单</h3>
    </div>
    <div class="container" ref="container">
      <div class="top" style="margin-bottom: 20px">
        <el-form ref="filterForm" v-model="filterForm" inline>
          <el-form-item label="奖品名称：">
            <el-input v-model="filterForm.goods_name" clearable placeholder="请输入奖品名称"></el-input>
          </el-form-item>
          <el-form-item label="中奖人手机号：">
            <el-input v-model="filterForm.user_phone" clearable placeholder="请输入中奖人名称"></el-input>
          </el-form-item>
          <br/>
          <el-form-item label="中奖时间：">
            <el-date-picker
                v-model="Daterange"
                type="daterange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="left"
                value-format="timestamp"
                clearable
            />
          </el-form-item>
          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="serchTable">搜索</el-button>
          </el-form-item>
        </el-form>
        <el-button type="primary" icon="el-icon-download" @click="exportShow = true">导出</el-button>
      </div>
      <div class="content">
        <el-table
            :data="tableData"
            border
            :max-height="containerHeight - 250"
            stripe
        >
          <template v-for="(item, index) in tableColumn">
            <el-table-column
                v-if="item.prop == 'createtime'"
                :key="'a'+index"
                :width="item.width"
                :fixed="item.fixed"
                :prop="item.prop"
                :label="item.label"
                align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.createtime ? filterTime(scope.row.createtime): '' }}
              </template>
            </el-table-column>
            <el-table-column
                v-else
                :key="'a'+index"
                :prop="item.prop"
                :label="item.label"
                align="center"
            />
          </template>
        </el-table>
      </div>
      <el-pagination
          @current-change="SetPage"
          :current-page="filterForm.page"
          :page-size="filterForm.limit"
          layout="total, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="导出范围" :visible.sync="exportShow" width="25%">
      <div>
        <p style="padding: 0 0 20px 0; font-size: 12px; color: #e6a23c">
          将导出满足筛选条件的门店!
        </p>
        <el-radio v-model="exportType" :label="1">当前页</el-radio>
        <el-radio v-model="exportType" :label="2">导出所有</el-radio>

      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exportShow = false">取 消</el-button>
        <el-button type="primary" @click="exportList">确 定</el-button>
      </span>
    </el-dialog>
  </el-card>
</template>
<script>
import { getAllBagWinnerList } from "@/api/luckyBag";
import { getSystemsettings } from "@/api/getSystemsetting";
import {filenameFormat} from "@/utils";
export default {
  name: "luckyBagInfo",
  data() {
    return {
      // 容器宽高
      containerHeight: null,
      containerWidth: null,
      // oss前缀
      imgurl: '',
      // 导出弹窗提示
      exportShow: false,
      // 导出类型   1当前页 2全部
      exportType: '',
      // 筛选表单
      filterForm: {
        limit: 10,
        page: 1,
        username: '',
        goods_name: '',
        export: '',
        export_type: '',
        starttime: '',
        endtime: '',
        user_phone: ''
      },
      Daterange: [],
      total: 20,
      // 表格数据
      tableData: [],
      // 表头数据
      tableColumn: [
        {
          label: '福袋名称',
          prop: 'blessing_bag_title',
          width: 250,
          fixed: 'left',
        },
        {
          label: '中奖商品',
          prop: 'goods_name',
          width: 300,
          fixed: 'left',
        },
        {
          label: '中奖时间',
          prop: 'createtime',
          width: 100,
          fixed: false,
        },
        {
          label: '中奖直播间',
          prop: 'live_name',
          width: 100,
          fixed: false,
        },
        {
          label: '中奖人名称',
          prop: 'username',
          width: 200,
          fixed: false,
        },
        {
          label: '中奖人电话',
          prop: 'user_phone',
          width: 200,
          fixed: false,
        }
      ],
    }
  },
  watch: {
    Daterange: function (newVal, oldVal) {
      if (newVal != null) {
        this.filterForm.starttime = newVal[0]/1000
        this.filterForm.endtime = newVal[1]/1000
      } else {
        this.filterForm.starttime = "";
        this.filterForm.endtime = "";
      }
    }
  },
  methods: {
    /**
     * 分页器
     * @param e
     * @constructor
     */
    SetPage(e) {
      this.filterForm.page = e
      this.getList()
    },
    /**
     * 获取数据列表
     */
    async getList() {
      let { data } = await getAllBagWinnerList(this.filterForm)
      this.total = data.data.total
      this.tableData = data.data.data
    },
    /**
     * 搜索表格数据
     */
    serchTable() {
      this.filterForm.page = 1
      this.getList()
    },
    /**
     * 导出中奖名单
     */
    async exportList() {
      this.filterForm.export_type = this.exportType
      this.filterForm.export = 'export'
      let { data, request } = await getAllBagWinnerList(this.filterForm, "blob");
      let blob = new Blob([data], { type: "application/vnd.ms-excel" }); //type是文件类，详情可以参阅blob文件类型
      // let filename = decodeURI(
      //   request.getResponseHeader("content-disposition").split("=")[1]
      // );
      let objectUrl = URL.createObjectURL(blob); //生成下载链接
      let a = document.createElement("a"); //创建a标签用于文件下载
      a.href = objectUrl; //赋值下载路径
      a.download = filenameFormat(
          request.getResponseHeader("content-disposition")
      ); //下载的文件名称（非必填）
      document.body.appendChild(a); //插入DOM树
      a.click(); //点击a标签触发
      document.body.removeChild(a); //删除a标签
      this.filterForm.export_type = ''
      this.filterForm.export = ''
      this.exportShow = false
    },
    /**
     * 格式化时间
     * @param stamp       时间戳
     * @returns {string}  格式化完成的时间字符串
     */
    filterTime(stamp){
      let date = new Date(stamp*1000).toLocaleString('chinese',{hour12:false}).replace(/\//g, "-")
      return date
    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getList()
    getSystemsettings.then((res) => {
      console.log(res)
      this.imgurl = res;
    });
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.containerHeight = this.$refs['container'].offsetHeight
    console.log(this.containerHeight);
    this.containerWidth = this.$refs['container'].offsetWidth
  },
  //生命周期 - 创建之前
  beforeCreate() {
  },
  //生命周期 - 挂载之前
  beforeMount() {
  },
  //生命周期 - 更新之前
  beforeUpdate() {
  },
  //生命周期 - 更新之后
  updated() {
  },
  //生命周期 - 销毁之前
  beforeDestroy() {
  },
  //生命周期 - 销毁完成
  destroyed() {
  },
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
  },
}
</script>
<style scoped lang="less">
/deep/.el-card__body {
  height: 100%;
}
.container {
  padding: 20px;
  height: 100%;
  .option {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}
</style>
